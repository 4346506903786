export class ChartGenerator {
  private chart: any;

  constructor(public echarts: any, public jQuery: any, public window: any) {}

  createChart(elementId: string, title: string, data: (any|number)[], chartType: string,
    customizationOptions: {[key: string]: any}, resizeTriggerElement: string) {

    let option: {[key: string]: any} = {};
    this.chart = this.echarts.init(document.getElementById(elementId));

    option = this.addDefaultOption(data, title, option);
    option.xAxis = customizationOptions.xAxis;
    option.yAxis = customizationOptions.yAxis;
    option.dataZoom = customizationOptions.dataZoom;
    option.legend = customizationOptions.legend || { show: false };
    option.series = [{type: chartType}];

    const percentage = customizationOptions.percentage;

    switch(chartType) {
      case 'pie':
        option = this.addDefaultPieChartOptions(option, data);
        if (customizationOptions.colors) option.color = customizationOptions.colors;
        break;
      case 'treemap':
        option = this.addDefaultTreemapChartOptions(option, data, customizationOptions.rootName);
        break;
      case 'stacked_bar':
        option = this.addDefaultStackedBarOptions(option, data, customizationOptions.numberOfBars);
        if (customizationOptions.colors) option.color = customizationOptions.colors;
        break;
      case 'bar':
        if (percentage) option = this.addDefaultPercentageOptions(option);
        if (customizationOptions.colors) {
          option.series[0].data = data;
          if (option.xAxis.type === "category") {
            option.xAxis.data = customizationOptions.colors;
          } else {
            option.yAxis.data = customizationOptions.colors;
          }
        }
        break;
      case 'line':
        if (percentage) option = this.addDefaultPercentageOptions(option);
        break;
      case 'map':
        option = customizationOptions;
    }

    this.chart.setOption(option);

    this.addResizeTriggers(resizeTriggerElement);
  }

  createColorMap(elementId: string, title: string, dataHash: {[key: string]: any}, resizeTriggerElement: string, area: string) {
    this.jQuery.get('demographic_divisions/geojson/' + area)
      .done((geoJson) => {
        this.echarts.registerMap(area, geoJson);
        const option: {[key: string]: any} = {};

        const data = (`data` in dataHash) ? dataHash.data : {};
        option.series = [{type: 'map', map: area, data}];
        option.textStyle = { fontFamily: 'Verdana, sans-serif', fontsize: 20 };
        option.title = { text: title };
        option.tooltip = {
          formatter(params: any) : string {
            const value: number = Number.isNaN(params.value) ? 0 : params.value;
            const percentage: number = !(params.data) ? '0%' : params.data.percentage;
            return `<b>${params.name}: </b>${value} (${percentage})`;
          }
        };
        const max = ((`max_value` in dataHash) && !Number.isNaN(dataHash.max_value)) ? dataHash.max_value : 0;
        option.visualMap = {
          min: 0,
          max,
          text: [`${max}`, '0'],
          inRange: { color: ['lightblue', 'yellow', 'red']}
        };

        this.createChart(elementId, title, data, 'map', option, resizeTriggerElement);
      });
  }

  private addDefaultOption(data: (any|number)[], title: string, option: any): any {
    option.dataset = { source: data };
    option.grid = { containLabel: true };
    option.textStyle = { fontFamily: 'Verdana, sans-serif', fontsize: 20 };
    option.title = { text: title };
    option.tooltip = {
      trigger: 'axis',
      formatter(params: any) : string {
        return `<b>${params[0].data.name}:</b> ${params[0].data.value}`;
      }
    };
    return option;
  }

  private addResizeTriggers(elementId: string) {
    this.jQuery(elementId).bind('shown.bs.collapse', () => {
      this.chart.resize();
    });

    this.jQuery(this.window).on('resize', () => {
      if (this.chart != null && this.chart !== undefined) {
        this.chart.resize();
      }
    });
  }

  private addDefaultPercentageOptions(options: any): any {
    options.tooltip = {
      trigger: 'axis',
      formatter(params: any) : string {
        return `<b>${params[0].data.name}:</b> ${params[0].data.value} (${params[0].data.percentage})`;
      }
    };
    return options;
  }

  private addDefaultTreemapChartOptions(options: any, data: (any|number)[], rootName: string): any {
    options.label = { fontsize: 16 }
    options.series = [{
      type: 'treemap',
      name: rootName,
      data
    }];
    options.tooltip = {
      formatter(params: any) : string {
        return `<b>${params.data.fullname}:</b> ${params.data.value} (${params.data.percentage})`;
      }
    };
    return options;
  }

  private addDefaultPieChartOptions(option: any, data: (any|number)[]): any {
    option.legend = {
      type: 'scroll',
      orient: 'vertical',
      right: 10,
      top: 20,
      bottom: 20,
      data: data.map((datum) => { return datum.name; }),
    };
    option.series = [{
      type: 'pie',
      radius: "55%",
      center: ['40%', '50%'],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }];
    option.tooltip = {
      trigger: 'item',
      formatter(params: any) : string {
        return `<b>${params.data.name}:</b> ${params.data.value} (${params.data.percentage})`
      }
    };

    return option;
  }

  private addDefaultStackedBarOptions(option: any, data: (any|number)[], numberOfBars: number): any {
    option.series = [];
    for (let i = 1; i <= numberOfBars; i++) {
      option.series.push({
        type: 'bar',
        stack: true,
        label: {
          normal: {
            show: true,
            position: 'insideLeft',
            formatter: (params: {[key: string]: any}) => {
              if (params.data[i] === 0) return '';
              return `${data[1][params.dataIndex][i]} (${params.data[i].toFixed(2)}%)`;
            }
          }
        }
      });
    }
    option.legend.data = data[0].slice(1);

    option.dataset = { source: data[2] };
    option.tooltip = {
      trigger: 'axis',
      axisPointer : { type : 'shadow' },
      formatter: (params: {[key: string]: any}) => {
        const seriesCircle = (color: string) => `<div class="rounded-circle" style="display:inline-block;width:10px;height:10px;background-color:${color}"></div>`;
        let html = `<b>${params[0].axisValue}</b><br>`;
        params.forEach((item: {[key: string]: any}) => {
          html += `${seriesCircle(item.color)} ${item.seriesName}: ${data[1][item.dataIndex][item.seriesIndex + 1]} (${item.data[item.seriesIndex + 1].toFixed(2)}%)<br>`;
        });
        html += `Total: ${data[3][params[0].dataIndex]}`;
        return html;
      }
    };
    return option;
  }
}
