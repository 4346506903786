export class GeneralData {
  private jQuery: any;

  constructor(jQuery: any) {
    this.jQuery = jQuery;
  }

  contentSelectFor(category: string, select: any): void {
    const tableId = `#${category}-${select.value}`;

    this.jQuery(`[id^="${category}"]`).css('display', 'none');
    this.jQuery(tableId).css('display', 'block');
  }

  dynamicChartFor(selected: string, data: any, chart: any): void {
    chart.echarts.dispose(document.getElementById('dynamic-chart'));
    if (data[selected][`chart_type`] === 'map') {
      chart.createColorMap(
        'dynamic-chart', '',
        data[selected][`data`],
        '#collapseGeneralDatadynamic',
        data[selected][`chart_area`]
      );
    } else {
      chart.createChart(
        'dynamic-chart', '',
        data[selected][`data`],
        data[selected][`chart_type`],
        data[selected][`chart_options`],
        '#collapseGeneralDatadynamic'
      );
    }
  }

  healthFacilitiesChartFor(selectedId: string, dataSet: any, chart: any): void {
    chart.echarts.dispose(document.getElementById('health-facility-specialty-distances-chart'));
    const selectedName = Object.keys(dataSet.data)[selectedId];
    chart.createChart(
      'health-facility-specialty-distances-chart',
      dataSet.chart_title,
      dataSet.data[selectedName],
      dataSet.chart_type,
      dataSet.chart_options,
      '#collapseGeneralDatahealth_facilities_specialty_distances'
    );
  }
}
