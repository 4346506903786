// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'bootstrap'
import 'bootstrap-select'
import 'bootstrap-slider'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.pt-BR'

import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/leaflet.markercluster.js';
import 'leaflet-minimap/dist/Control.MiniMap.min.js'
import 'leaflet-minimap/dist/Control.MiniMap.min.css'

import 'heatmap.js/build/heatmap.min.js';
import * as HeatmapOverlay from 'heatmap.js/plugins/leaflet-heatmap/leaflet-heatmap.js';
window.HeatmapOverlay = HeatmapOverlay;

// FIX Leaflet default icons with webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";

import html2canvas from 'html2canvas'

import './src/application.scss'

import { GeneralData } from './general_data/GeneralData';
window.GeneralData = GeneralData

import { ChartGenerator } from './general_data/ChartGenerator';
window.ChartGenerator = ChartGenerator

import { UserGuide } from './static_pages/UserGuide';
window.UserGuide = UserGuide

import { AboutPage } from './static_pages/AboutPage';
window.AboutPage = AboutPage

import echarts from 'echarts'
window.echarts = echarts

window.html2canvas = html2canvas;
window.jQuery = $;
window.$ = $;
