export class AboutPage {
  show_email(e: any): void {

    let elem = e.nextElementSibling;

    if (!elem) return;

    if (elem.style.display === "block"){
      elem.style.display = "none";
      elem = elem.nextElementSibling
      while (elem) {
        elem.style.display = "block";
        elem = elem.nextElementSibling;
      }
    }
    else {
      elem.style.display = "block";
      elem = elem.nextElementSibling
      while (elem) {
        elem.style.display = "none";
        elem = elem.nextElementSibling;
      }
    }
  }
}
