export class UserGuide {
  private currentPosition: number;

  constructor(public jQuery: any) {
    this.currentPosition = 1;
  }

  positionArrow(): void {
    const position = this.jQuery("#type" + this.currentPosition).offset().left +
                   this.jQuery("#type" + this.currentPosition).width()/2 - 37;
    this.jQuery("#triangle").css("transform", "translate(" + position + "px)");
  }

  changeFAQ(index: number = this.currentPosition): void {
    this.currentPosition = index;
    this.positionArrow();
    this.jQuery("#question-container").css("transform", "translate(0px)");
    const scroll = this.jQuery("#viewer").width();
    this.jQuery("#question-container").css("transform", "translate(-" + scroll * (index - 1) + "px)");
  }

  jump(id: string): void {
    const url = location.href;
    location.href = "#" + id;
    history.replaceState(null, null, url);
  }
}
